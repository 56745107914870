/**
 * Created by mac on 9/18/22
 */

var ShortMeta = function () {
    BaseLocation.call(this);
};

ShortMeta.prototype = Object.create(BaseLocation.prototype);
ShortMeta.prototype.constructor = ShortMeta;

ShortMeta.prototype.gamePlayed = function (outcome) {
    if (cleverapps.isKnockoutGame()) {
        if (cleverapps.knockoutGame.rumble.outcome === Rumble.OUTCOME_WIN) {
            this.multiplyRewards();
        }
    } else if (outcome === GameBase.OUTCOME_VICTORY) {
        this.multiplyRewards();
    }
};

ShortMeta.prototype.getReward = function (level) {
    return RewardsConfig.ShortMeta[level % 10];
};

ShortMeta.prototype.multiplyRewards = function () {
    var level = this.getProgress();

    var reward = this.getReward(level);
    if (reward) {
        for (var type in reward) {
            var amount = Game.currentGame.rewards[type] * (reward[type].factor || 1);
            amount -= Game.currentGame.rewards[type];

            if (type === GameBase.REWARD_HARD) {
                Game.currentGame.addHardReward(amount);
            } else if (type === GameBase.REWARD_SOFT) {
                Game.currentGame.addSoftReward(amount);
            }
        }
    }
};

ShortMeta.prototype.getProgress = function () {
    return cleverapps.user.getVirtualProgress();
};

ShortMeta.ListAvailableLocations = function () {
    return [0];
};

ShortMeta.ListActiveLocations = function (currentLocationId) {
    return [currentLocationId];
};

ShortMeta.NextLocationId = function (locationId) {
    return locationId + 1;
};
